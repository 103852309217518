import React from 'react';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ScrollButton from '../../utilities/ScrollButton';

import jesus from '../../images/jesus.svg';
import cloudLeft from '../../images/cloud-left.svg';
import cloudRight from '../../images/cloud-right.svg';
import bigCrosses from '../../images/big-crosses.svg';
import waveRed from '../../images/landing-page-wave-red.svg';
import waveBrown from '../../images/landing-page-wave-brown.svg';
import waveLightRed from '../../images/landing-page-wave-light-red.svg';
import basicWire from '../../images/basic-wire.svg';
import plan from '../../images/planspowiedzi.jpg';

import './Home.scss';
import { MdPadding } from 'react-icons/md';

const Home = () => {
  return (
    <div className='animation-wrapper'>
      <main className='default-container animation-section'>
        <Sidebar />
        <Navbar />

        <h1 className='landing-page-title'>
          <span className='first'>
            Parafia Chrystusa Dobrego Pasterza
          </span>{' '}
          w{' '}
          <span className='second'>
            Białych Błotach
          </span>
        </h1>

        <img
          src={cloudLeft}
          alt='chmura'
          className='landing-page-cloud left'
        />
        <img
          src={cloudRight}
          alt='chmura'
          className='landing-page-cloud right'
        />

        <img
          src={jesus}
          alt='Jezus'
          className='landing-page-jesus'
        />

        <img
          src={bigCrosses}
          alt='krzyże'
          className='landing-page-big-crosses'
        />

        <img
          src={waveLightRed}
          alt='jasno czerwona fala'
          className='landing-page-wave light-red'
        />
        <img
          src={waveRed}
          alt='czerwona fala'
          className='landing-page-wave red'
        />
        <img
          src={waveBrown}
          alt='brazowa fala'
          className='landing-page-wave brown'
        />

        <ScrollButton />
      </main>
      <section className='animation-section'>
        <div className='default-container'>
          <Navbar />
          <Sidebar />
          <article className='default-content-wrapper-without-img'>
            <h2 className='subpage-title-center'>
              Ogłoszenia parafialne
            </h2>
            <h3 className='ogloszenia-title'>
              III Niedziela Zwykła
            </h3>
            <h4 className='ogloszenia-date'>
              26 stycznia 2025 r.
            </h4>
            <br />
            <ol
              type='1'
              start='1'
              className='ogloszenia-list'
            >
              <li>
                Dziękujemy za wszystkie ofiary
                składane na tacę tradycyjną i
                elektroniczną oraz za wszystkie
                ofiary złożone w ostatnim czasie
                na cele gospodarcze od rodzin: z
                ul. Dalekiej 200 zł, Ezopa 200
                zł., ul. Gwarnej 200 zł., ul.
                Betonowej 100 zł. W czasie
                trwającej wizyty duszpasterskiej
                szczególnie dziękujemy za
                wszystkie ofiary przekazywane na
                wsparcie świątyni oraz ogrzewanie.
                W ubiegłą niedzielę z racji III
                niedzieli miesiąca na tacę
                gospodarczo – remontową zebraliśmy
                3847 zł. Za każdy dar serca
                składamy serdeczne Bóg zapłać!
              </li>
              <li>
                W tym tygodniu w liturgii czcimy:
                <br />• we wtorek – św. Tomasza z
                Akwinu, dziewicę i Doktora
                Kościoła;
                <br />• w piątek – św. Jana Bosko,
                prezbitera;
                <br />• w sobotę – przypada I
                sobota miesiąca o godz. 7.30
                różaniec oraz o godz. 8.00 Msza
                Święta wotywna o Niepokalanym
                Sercu NMP. Przedpołudniem
                odwiedzimy chorych ze stałej listy
                z posługą sakramentalną.
              </li>
              <li>
                W przyszłą niedzielę przeżywamy
                Święto Ofiarowania Pańskiego, w
                tym dniu przynosimy do świątyni
                świece, które podczas liturgii
                błogosławimy. Jest to również
                Światowy Dzień Życia
                Konsekrowanego dlatego ofiarami do
                puszek wspieramy klasztory
                klauzurowe.
              </li>
              <li>
                Za tydzień I niedziela miesiąca na
                Mszę Świętą o godz. 10.00 z
                odnowieniem przyrzeczeń
                małżeńskich zapraszamy wszystkie
                pary, które ślubowały w miesiącu
                lutym. Po zakończonej Eucharystii
                zapraszamy na spotkanie przy kawie
                i ciastku w „Kawiarence Dobrego
                Pasterza”.
              </li>
              <li>
                Kończymy czas wizyty
                duszpasterskiej w naszej parafii.
                W piątek ostatni dzień kolędy.
                Jeśli są osoby, które chciałby
                jeszcze przyjąć kapłana z
                błogosławieństwem domu to prosimy
                o zgłoszenie po Mszy Świętej w
                zakrystii. Na dodatkowe zgłoszenia
                kolędowe udamy się również w
                piątek 31 stycznia od godz. 16.00.
                W środę natomiast 5 lutego o godz.
                18.00 odprawimy Mszę Świętą w
                intencji rodzin naszej parafii o
                Boże błogosławieństwo. Plan kolędy
                jest wywieszony w gablocie, na
                stronie internetowej i facebooku.
                Dziękujemy za gościnę w Waszych
                domach, rodzinną atmosferę i za
                wszystkie ofiary składane na
                potrzeby naszej parafii.
              </li>
              <li>
                W tym roku planujemy parafialną
                samolotową pielgrzymkę do
                Portugalii z Biurem Pielgrzymkowym
                Arcus w terminie 22-29 września. W
                programie Fatima, Lizbona, Porto,
                Nazare, Santiago de Compostela.
                Koszt pielgrzymki od osoby to 5550
                zł. Szczegóły na plakacie w
                gablocie oraz wyłożone na stoliku
                pod chórem. Zapraszamy osoby,
                które zapisały się oraz te, które
                jeszcze chcą się zapisać na
                spotkanie organizacyjne 5 lutego
                (środa) po wieczornej Mszy Świętej
                około 18.30 w salce parafialnej.
              </li>
              <li>
                Również w tym roku w terminie 9–13
                czerwca planujemy pielgrzymkę
                autokarową w kraju Bieszczady –
                Podkarpacie 5 dni. W programie
                między innymi: Święty Krzyż,
                Komańcza, Solina, Kalwaria
                Pacławska, Przemyśl, Krasiczyn,
                Markowa, Łańcut, Sandomierz.
                Przybliżony koszt pielgrzymki to
                2300 zł. Szczegóły na plakacie w
                gablocie.
              </li>
              <li>
                We wtorek 11 lutego wspominać
                będziemy w liturgii NMP z Lourdes.
                Jest to również kolejny Światowy
                Dzień Chorego. Wydarzenie to
                uwrażliwia nas na sprawy ludzi
                starszych i chorych, ale przede
                wszystkim mobilizuje nas do
                okazywania uczynków miłosierdzia
                wobec tych ludzi. W naszej parafii
                Msza Święta dla chorych z
                sakramentem namaszczenia oraz
                indywidualnym błogosławieństwem
                Najświętszym Sakramentem we wtorek
                11 lutego o godzinie 18.00.
                Prosimy bliskich, sąsiadów oraz
                ludzi dobrej woli o pomoc w
                dotarciu do kościoła osób
                starszych i niepełnosprawnych.
                Osoby chore i starsze, które będą
                przyjmować sakrament chorych
                prosimy, aby przyniosły ze sobą
                karteczki na których zapisujemy
                imię i nazwisko, wiek i adres
                zamieszkania.
              </li>
              <li>
                Zachęcamy do czytania prasy
                katolickiej. W „Przewodniku
                katolickim” między innymi artykuły
                dotyczące roli Pisma Świętego w
                naszym chrześcijańskim życiu oraz
                informacje z życia naszej
                diecezji. W tym numerze
                modlitewnik na miesiąc luty.
              </li>
              <li>
                W minionym tygodniu odeszli do
                Pana:
                <br />† Renata Jaszkowska, lat 67,
                zam. ul. Czajcza;
                <br />† Krzysztof Bąkowski, lat
                64, zam. ul. Cukierniczej;
                <br />† Danuta Woźniak, lat 78,
                zam. w ostatnim czasie w
                Bydgoszczy;
                <br />† Marian Buraczewski, lat
                68, zam. w Bydgoszczy. Pogrzeb we
                wtorek o godz. 13.00 na cmentarzu
                w Białych Błotach.
                <br />
                …wieczny odpoczynek racz im dać
                Panie…
              </li>
            </ol>
            {/* <img
              src={plan}
              className='default-border planspowiedzi'
              alt='Plan Spowiedzi'
            ></img> */}
          </article>
          <article className='default-content-wrapper-without-img'>
            <h2 className='subpage-title-center'>
              Plan wizyty duszpasterskiej w roku
              2025
            </h2>
            <h4 className='ogloszenia-title koleda-center '>
              Kolędę w tygodniu od poniedziałku do
              piątku rozpoczynamy od godz. 16.00,
              a w soboty od godz. 11.00.
            </h4>
            <br />
            <br />
            <br />
            <table
              style={{
                width: '100%',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
              class='ogloszenia-list koleda-table'
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Ulica</th>
                  <th>Ulica</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>27.12 2024 r.</td>
                  <td>Ostróżki, Gwarna</td>
                  <td>
                    Hodowlana - od końca, Rzepichy
                  </td>
                </tr>

                <tr>
                  <td>28.12 2024 r.</td>
                  <td>
                    Przemysłowa, Zacisze,
                    Kapliczna, Wierzbowa,
                    Szubińska 14
                  </td>
                  <td>
                    Szubińska (od str. Urzędu
                    Gminy) do nr 87E
                  </td>
                </tr>

                <tr>
                  <td>30.12 2024 r.</td>
                  <td>
                    Hippiczna (str. parzysta)
                  </td>
                  <td>
                    Hippiczna (str. nieparzysta)
                  </td>
                </tr>

                <tr>
                  <td>2.01 2025 r.</td>
                  <td>
                    Temidy, Jantarowa nr 8 i 10
                  </td>
                  <td>Gontowa</td>
                </tr>

                <tr>
                  <td>3.01 2025 r.</td>
                  <td>
                    Czahary (od nr 2-34 i 3-27)
                  </td>
                  <td>
                    Sielska, Czahary (od nr 46-32
                    i 43-33), Sobótki, Parkowa
                  </td>
                </tr>
                <tr>
                  <td>4.01 2025 r.</td>
                  <td>Olchowa, Literacka</td>
                  <td>
                    Jantarowa (od końca), Żeńców,
                    Jutrzenki, Kadetów
                  </td>
                </tr>
                <tr>
                  <td>7.01 2025 r.</td>
                  <td>Ludowa, Uroczyska</td>
                  <td>
                    Modra (od 20 do 2), Letnia,
                    Moczary
                  </td>
                </tr>
                <tr>
                  <td>8.01 2025 r.</td>
                  <td>
                    Barycka (od 1-36- do
                    Czerskiej), Chlebowa 2A, 2 B i
                    2C
                  </td>
                  <td>
                    Barcycka (od 37A-45 i od 40
                    -62), Popiela, Mimozy, Reduty
                  </td>
                </tr>
                <tr>
                  <td>9.01 2025 r.</td>
                  <td>Czerska (od 2 -36)</td>
                  <td>Czerska (od 1-55)</td>
                </tr>
                <tr>
                  <td>10.01 2025 r.</td>
                  <td>
                    Goplany, Herbowa, Hebanowa
                  </td>
                  <td>Guliwera</td>
                </tr>
                <tr>
                  <td>11.01 2025 r.</td>
                  <td>Barwinkowa</td>
                  <td>
                    Alpejska, Arlekina, Bazaltowa,
                    Epokowa
                  </td>
                </tr>
                <tr>
                  <td>13.01 2025 r.</td>
                  <td>
                    Dworska. Jaracza, Judyma
                  </td>
                  <td>
                    Forteczna, Owcza, Jutrzenki 1
                    i 1A, Feniksa
                  </td>
                </tr>
                <tr>
                  <td>14.01 2025 r.</td>
                  <td>
                    Cisowa, Centralna (od 47-59;
                    od 68-96)
                  </td>
                  <td>
                    Centralna (od końca 111-79 i
                    112 -92), Młoda
                  </td>
                </tr>
                <tr>
                  <td>15.01 2025 r.</td>
                  <td>
                    Niedzielna, Miła, Lechicka
                  </td>
                  <td>
                    Mokra, Piesza, Turkusowa,
                    Trawiasta
                  </td>
                </tr>
                <tr>
                  <td>16.01 2025 r.</td>
                  <td>
                    Nizinna, Lutników, Ogniskowa
                  </td>
                  <td>Czerska (od 57-111)</td>
                </tr>
                <tr>
                  <td>17.01 2025 r.</td>
                  <td>
                    Centralna (od 2 – 66 i od 9-
                    25A)
                  </td>
                  <td>
                    Czarnoleska, Chełmska, Cukrowa
                  </td>
                </tr>
                <tr>
                  <td>18.01 2025 r.</td>
                  <td>Czekoladowa</td>
                  <td>
                    Centralna (od 41A-27), Czysta,
                    Bajeczna
                  </td>
                </tr>
                <tr>
                  <td>20.01 2025 r.</td>
                  <td>Chmielna, Chmielarska</td>
                  <td>Cedrowa, Cyprysowa</td>
                </tr>
                <tr>
                  <td>21.01 2025 r.</td>
                  <td>Chlebowa (od 2-62)</td>
                  <td>Gronowa, Czwartaków</td>
                </tr>
                <tr>
                  <td>22.01 2025 r.</td>
                  <td>Chlebowa (od 7 – 83)</td>
                  <td>
                    Chlebowa (od 87 – 105, od 64 –
                    76), Zawiła, Czeremchy
                  </td>
                </tr>
                <tr>
                  <td>23.01 2025 r.</td>
                  <td>Chudoby</td>
                  <td>Cukiernicza, Ulana</td>
                </tr>
                <tr>
                  <td>24.01 2025 r.</td>
                  <td>
                    Duńska, Cała, Ezopa, Boruty
                  </td>
                  <td>Czajcza, Zeusa, Iglasta</td>
                </tr>
                <tr>
                  <td>25.01 2025 r.</td>
                  <td>Daleka, Żaków, Izoldy</td>
                  <td>Drzewna</td>
                </tr>
                <tr>
                  <td>27.01 2025 r.</td>
                  <td>Cynowa, Daliowa</td>
                  <td>
                    Berberysowa, Bałkańska,
                    Bagatela
                  </td>
                </tr>
                <tr>
                  <td>28.01 2025 r.</td>
                  <td>Blok 2</td>
                  <td>Bloki 4 i 6</td>
                </tr>
                <tr>
                  <td>29.01 2025 r.</td>
                  <td>Bracka, Bartnicza</td>
                  <td>
                    Biała, Betonowa 1A, Altanowa
                  </td>
                </tr>
                <tr>
                  <td>30.01 2025 r.</td>
                  <td>
                    Azalowa, Ametystowa,
                    Asfaltowa, Leśna, Jaśminowa
                  </td>
                  <td>
                    Betonowa (od 8- 40), Alibaby
                  </td>
                </tr>
                <tr>
                  <td>31.01 2025 r.</td>
                  <td>
                    Tulipanowa, Konwaliowa,
                    Różana, Żonkilowa, Szafirowa
                  </td>
                  <td>Dodatkowe zgłoszenia</td>
                </tr>
              </tbody>
            </table>
            {/*
            <table
              style={{
                width: '100%',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
              class='ogloszenia-list koleda-table'
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Ulica</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2.01, 16:00</td>
                  <td>
                    ul. Betonowa blok nr 2, ul.
                    Berberysowa, ul. Bałkańska,
                    ul. Bagatela
                  </td>
                </tr>
                <tr>
                  <td>3.01, 16:00</td>
                  <td>
                    ul. Betonowa blok nr 4 i blok
                    nr 6 oraz ul. Bracka, ul.
                    Bartnicza
                  </td>
                </tr>
                <tr>
                  <td>4.01, 16:00</td>
                  <td>
                    ul. Betonowa domki, ul.
                    Altanowa, ul. Alibaby, ul.
                    Szubińska nr 4-12, ul. Biała,
                    ul. Betonowa 1a
                  </td>
                </tr>
                <tr>
                  <td>5.01, 16:00</td>
                  <td>
                    ul. Azalowa, ul. Ametystowa,
                    ul. Asfaltowa, ul. Jaśminowa,
                    ul. Leśna, ul. Konwaliowa, ul.
                    Szafirowa, ul. Tulipanowa, ul.
                    Żonkilowa, ul. Różana
                  </td>
                </tr>
                <tr>
                  <td>8.01, 16:00</td>
                  <td>
                    ul. Chmielna, ul. Chmielarska,
                    ul. Czarnoleska, ul. Chełmska,
                    ul. Cukrowa
                  </td>
                </tr>
                <tr>
                  <td>9.01, 16:00</td>
                  <td>
                    ul. Chlebowa 7-83, ul.
                    Iglasta, ul. Izoldy, ul.
                    Cedrowa, ul. Cyprysowa
                  </td>
                </tr>
                <tr>
                  <td>10.01, 16:00</td>
                  <td>
                    ul. Chlebowa 2-76, ul.
                    Gronowa, ul. Czwartaków
                  </td>
                </tr>
                <tr>
                  <td>11.01, 16:00</td>
                  <td>
                    ul. Chlebowa 85-105, ul.
                    Boruty, ul. Zawiła, ul.
                    Czeremchy, ul. Cynowa, ul.
                    Daliowa
                  </td>
                </tr>
                <tr>
                  <td>12.01, 16:00</td>
                  <td>
                    ul. Czysta, ul. Bajeczna, ul.
                    Czekoladow
                  </td>
                </tr>
                <tr>
                  <td>13.01, 11:00</td>
                  <td>
                    ul. Chudoby, ul. Cukiernicza,
                    ul. Ulana
                  </td>
                </tr>
                <tr>
                  <td>15.01, 16:00</td>
                  <td>ul. Gontowa, ul. Gwarna</td>
                </tr>
                <tr>
                  <td>16.01, 16:00</td>
                  <td>
                    ul. Drzewna, ul. Czajcza, ul.
                    Zeusa
                  </td>
                </tr>
                <tr>
                  <td>17.01, 16:00</td>
                  <td>
                    ul. Duńska, ul. Ezopa, ul.
                    Daleka, ul. Żaków, ul. Cała
                  </td>
                </tr>
                <tr>
                  <td>18.01, 16:00</td>
                  <td>
                    ul. Guliwera, ul. Modra, ul.
                    Letnia, ul. Moczary
                  </td>
                </tr>
                <tr>
                  <td>19.01, 16:00</td>
                  <td>
                    ul. Ostróżki, ul. Szubińska 87
                    E, ul. Przemysłowa, ul.
                    Wierzbowa, ul. Zacisze, ul.
                    Kapliczna, ul. Szubińska
                  </td>
                </tr>
                <tr>
                  <td>20.01, 11:00</td>
                  <td>
                    ul. Centralna 2-66 i 9-41a,
                    ul. Cisowa, ul. Centralna 47 i
                    68 do 110 i 113
                  </td>
                </tr>
                <tr>
                  <td>22.01, 16:00</td>
                  <td>
                    ul. Jaracza, ul. Czerska
                    57-111, ul. Forteczna, ul.
                    Owcza, ul. Feniksa, ul.
                    Jutrzenki 1 i 1a, ul. Judyma
                  </td>
                </tr>
                <tr>
                  <td>23.01, 16:00</td>
                  <td>
                    ul. Czerska 2-36, ul. Czerska
                    1-55
                  </td>
                </tr>
                <tr>
                  <td>24.01, 16:00</td>
                  <td>
                    ul. Czahary od 2-34 i 3-27,
                    ul. Sielska, ul. Czahary(od
                    numerów końcowych), ul.
                    Sobótki, ul. Parkowa
                  </td>
                </tr>
                <tr>
                  <td>25.01, 16:00</td>
                  <td>
                    ul. Literacka, ul. Olchowa
                  </td>
                </tr>
                <tr>
                  <td>26.01, 16:00</td>
                  <td>
                    ul. Hodowlana, ul. Rzepichy,
                    ul. Ludowa
                  </td>
                </tr>
                <tr>
                  <td>27.01, 11:00</td>
                  <td>
                    ul. Hippiczna - nr
                    nieparzyste, ul. Hippiczna –
                    nr parzyste
                  </td>
                </tr>
                <tr>
                  <td>29.01, 16:00</td>
                  <td>
                    ul. Barwinkowa, ul. Epokowa,
                    ul. Barwinkowa 1-29 i 26-28c
                  </td>
                </tr>
                <tr>
                  <td>30.01, 16:00</td>
                  <td>
                    ul. Goplany, ul. Herbowa, ul.
                    Hebanowa, ul. Dworska, ul.
                    Arlekina, ul. Alpejska, ul.
                    Bazaltowa
                  </td>
                </tr>
                <tr>
                  <td>
                    &nbsp;31.01, 16:00&nbsp;
                  </td>
                  <td>
                    ul. Niedzielna, ul. Miła, ul.
                    Lechicka, ul. Młoda, ul.
                    Turkusowa, ul. Piesza, ul.
                    Mokra
                  </td>
                </tr>
                <tr>
                  <td>1.02, 16:00</td>
                  <td>
                    ul. Temidy, ul. Jantarowej 8 i
                    10, ul. Lutników, ul.
                    Ogniskowa, ul. Trawiasta, ul.
                    Nizinna
                  </td>
                </tr>
                <tr>
                  <td>3.02, 11:00</td>
                  <td>
                    ul. Żeńców, ul. Kadetów, ul.
                    Jantarowa, ul. Uroczysko, ul.
                    Barycka
                  </td>
                </tr>
                <tr>
                  <td>5.02, 16:00</td>
                  <td>
                    ul. Popiela, ul. Moczary, ul.
                    Reduty, ul. Jutrzenki
                  </td>
                </tr>
              </tbody>
            </table>
            */}
          </article>
          <img
            className='basic-wire'
            src={basicWire}
            alt='fala'
          />
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
