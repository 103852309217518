import React from 'react';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ScrollButton from '../../utilities/ScrollButton';

import basicWire from '../../images/basic-wire.svg';
import brownBasicWire from '../../images/brown-basic-wire.svg';
import redBasicWire from '../../images/red-basic-wire.svg';
import priest from '../../images/priest.svg';
import robert from '../../images/robert.png';

import './Duszpasterze.scss';

const Duszpasterze = () => {
  return (
    <div className='animation-wrapper'>
      <main className='default-container animation-section'>
        <Navbar />
        <Sidebar />
        <article className='default-content-wrapper-without-img'>
          <h1 className='subpage-title-center'>
            Duszpasterze
          </h1>
          <div className='flexbox-wrapper'>
            <div className='duszpasterze-flexbox-element'>
              <figure>
                <img
                  src={robert}
                  className='default-border'
                  alt='Probosz - Arkadiusz Muzolf'
                ></img>
                <figcaption>
                  <strong>
                    Ks. Arkadiusz Muzolf
                  </strong>
                  {/* <br />
                  ust. 26.06.2017 r.
                  <br /> */}
                  <br />
                  ust. 9.07.2023 r.
                  <br />
                  {/* <a
                    href='http://www.ewasilewski.pl/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    www.ewasilewski.pl
                  </a> */}
                </figcaption>
              </figure>
            </div>
            <div className='duszpasterze-flexbox-element'>
              <figure>
                <img
                  src={robert}
                  className='default-border'
                  alt='Wikariusz - Robert Lewandowski'
                ></img>
                <figcaption>
                  <strong>
                    Ks. Robert Schwarz
                  </strong>
                  <br />
                  ust. 2024 r.
                  <br />
                </figcaption>
              </figure>
            </div>
          </div>
        </article>
        <img
          className='basic-wire'
          src={basicWire}
          alt='fala'
        />
        <ScrollButton />
      </main>
      <div className='animation-section'>
        <section className='default-container'>
          <Navbar />
          <Sidebar />
          <article
            className='default-content-wrapper'
            id='poprzedni-kaplani-content-wrapper'
          >
            <h1 className='subpage-title-left'>
              Poprzedni Kapłani
            </h1>
            <div className='poprzedni-kaplani-content'>
              <strong>Proboszczowie:</strong>
              <br />
              1. + ks. Włodzimierz Reformat
              1978-1980 (wikariusz, ekonom i
              administrator), na urzędzie
              proboszcza 1980-1986.
              <br />
              2. ks. kanonik Franciszek Sołtysiak
              na urzędzie proboszcza 1986-2009.
              <br />
              3. + ks. Zbigniew Ciorkowski na
              urzędzie proboszcza 2009-2012.
              <br />
              4. ks. dr Adam Sierzchuła na
              urzędzie proboszcza 2012-2017.
              <br />
              5. ks. dr hab. Edward Wasilewski na
              urzędzie proboszcza 2017-2023.
              <br />
              6. ks. Arkadiusz Muzolf na urzędzie
              proboszcza od 2023.
              <br />
              <br />
              <strong>Wikariusze:</strong> <br />
              1. ks. Paweł Błoch 1986-1987.
              <br />
              2. + ks. Tadeusz Leśniewicz
              1988-1992 rezydent.
              <br />
              3. ks. Piotr Buczkowski 1992-1994.
              <br />
              4.ks. Mariusz Zbieranek 1994-1995.
              <br />
              5. ks. Waldemar Ćwik 1995-1997.
              <br />
              6. + ks. Wojciech Deluk 1997-2002.
              <br />
              7. ks. Mariusz Gębka 2002-2004.
              <br />
              8. ks. dr hab.Mariusz Kuciński
              2004-2008 rezydent.
              <br />
              9. ks. Przemysław Kodzik 2004-2005.
              <br />
              10. ks. Sylwester Olszanowski
              2005-2011.
              <br />
              11. ks. Łukasz Żarek 2011-2013.
              <br />
              12. ks. Marcin Woś 2013-2016.
              <br />
              13. ks. Dawid Sewruk 2016-2021.
              <br />
              14. + ks. Profesor dr hab.
              Franciszek Drączkowski 2017-2018
              pomoc duszpasterska.
              <br />
              15. ks. Andrzej Śliwarski od 2018
              pomoc duszpasterska.
              <br />
              16. ks. Robert Lewandowski od 2021.
              <br />
              <br />
              <strong>
                Pochodzący z parafii:
              </strong>
              <br />
              1. ks. Artur Kardaś CR, wyś.
              11.05.1991
              <br />
              2. ks. Adam Stachowiak, wyś.
              21.05.2005
            </div>
          </article>
          <img
            className='poprzedni-kaplani-priest'
            src={priest}
            alt='krzyze'
          />
          <img
            className='basic-wire brown'
            src={brownBasicWire}
            alt='fala'
          />
          <img
            className='basic-wire red'
            src={redBasicWire}
            alt='fala'
          />
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Duszpasterze;
