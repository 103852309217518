import React from 'react';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import basicWire from '../../images/basic-wire.svg';

import './Intencje.scss';

const Intencje = () => {
  return (
    <div className='animation-wrapper'>
      <div className=' animation-section'>
        <main className='default-container'>
          <Navbar />
          <Sidebar />
          <section className='default-content-wrapper-without-img'>
            <h1 className='subpage-title-center'>
              Intencje Mszalne
            </h1>
            <article className='flexbox-wrapper intencje-flexbox'>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Niedziela 26.01.2025 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Uroczystość Najśw. Trójcy
                  </h4> */}
                  <br />
                  <b>8:00</b> Z okazji 60-ej
                  rocznicy ślubu Jadwigi i Jerzego
                  Jankowskich
                  <br />
                  <b>8:00</b> + Stanisław Sikorski
                  (1 rocz. śm.)
                  <br />
                  <b>10:00</b> + za zm. dziadków
                  Rudeckich, Gwiździel, Wiese i
                  Bronka – z ok. dnia babci i
                  dziadka
                  <br />
                  <b>10:00</b> + syn Piotr (4
                  rocz. śm.)
                  <br />
                  <b>10:00</b> Rezerwacja
                  <br />
                  <b>12:00</b> + Magdalena (1 r.
                  śm. ), Józef i Michał Kaźmierscy
                  <br />
                  <b>18:00</b> + Ryszard Głogowski
                  – int. od mamy i rodzeństwa
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Niedziela 2.02.2025 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Uroczystość Najśw. Trójcy
                  </h4> */}
                  <br />
                  <b>8:00</b> + Mieczysław
                  Radziszewski oraz rodzice i
                  rodzeństwo z obojga stron
                  <br />
                  <b>10:00</b> O Boże błog., łaskę
                  zdrowia dla córki Anny i jej
                  synów oraz dla synów Piotra i
                  Janusza i ich rodzin
                  <br />
                  <b>10:00</b> + Janina i Stefan
                  Barańscy oraz zm. rodzice i
                  rodzeństwo z obojga stron
                  <br />
                  <b>12:00</b> + Marian Klimowski
                  (z ok. imienin), Eugeniusz,
                  Bronisława(k), Jadwiga, Jerzy i
                  Klara
                  <br />
                  <b>18:00</b> + Jadwiga i Adam
                  Grzywińscy oraz bracia Rudolf,
                  Witold i Jerzy
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Poniedziałek 27.01.2025 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Urocz. Objawienia Pańskiego
                  </h4> */}
                  <br />
                  <b>8:00</b> + Jolanta i Roman
                  Musiał
                  <br />
                  <b>18:00</b> Wolna intencja
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Wtorek 28.01.2025 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    św.Tomasza z Akwinu
                  </h4>
                  <br />
                  <b>8:00</b> + W intencji dusz
                  czyśćcowych – o łaskę zbawienia
                  <br />
                  <b>18:00</b> + Irena Słowińska –
                  int. od męża
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Środa 29.01.2025 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Uroczystość Świętej bożej
                    Rodzicielki Maryi
                  </h4> */}
                  <br />
                  <b>8:00</b> Wolna intencja
                  <br />
                  <b>18:00</b> W intencji Nowenny
                  do MBNP:
                  <br />+ Henryk Siekierski (10
                  rocz. śm.)
                  <br />+ Za zm. przyjaciół z
                  rodziny Szynalów – Teresa,
                  Józef, Marian i Janina
                  <br />+ Zdzisław Szmyciński –
                  int. od siostry Haliny z rodziną
                  <br />+ Renata Jaszkowska – int.
                  od Zakładu Pogrzebowego z
                  Białych Błot
                  <br />+ Aldona Gregorkiewicz –
                  int. od uczestników pogrzebu
                  <br />+ Danuta Woźniak – int. od
                  Zakładu Pogrzebowego z Białych
                  Błot
                  <br />
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Czwartek 30.01.2025 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Św. Bazylego i Grzegorza
                  </h4> */}
                  <br />
                  <b>8:00</b> Wolna intencja
                  <br />
                  <b>18:00</b> Dziękczynna z ok. 9
                  rocz. ślubu Ewy i Wiesława, z
                  prośbą o Boże błog. opiekę MB
                  Pięknej Miłości i wszelkie łaski
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Piątek 31.01.2025 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    Św. Jan Bosko
                  </h4>
                  <br />
                  <b>8:00</b> W int. Tomasza
                  Januszewskiego z ok. 52 rocz.
                  ur. z prośbą o Boże błog., łaskę
                  wiary i opiekę MB
                  <br />
                  <b>18:00</b> + Jerzy Twardowski
                  (3 rocz. śm.), zm. rodziców: Jan
                  i Kazimiera oraz Franciszka(k) i
                  Wincenty Agatowscy
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Sobota 1.02.2025 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Święto Nawr. Św. Pawła Ap.
                  </h4> */}
                  <br />
                  <b>8:00</b> + W intencji dusz
                  czyśćcowych – o łaskę zbawienia
                  <br />
                  <b>18:00</b> Dziękczynna z ok.
                  18-tych ur. Karoliny
                  Wiaderkiewicz, z prośbą o Boże
                  błog. opiekę MB oraz światło
                  Ducha Św. podczas egz.
                  maturalnych
                  <br />
                  <b>18:00</b> Dziękczynna z ok.
                  81 rocz. ur. Kazimiery
                  Fojutowskiej, z prośbą o Boże
                  błog. opiekę MB i wszelkie łaski
                </div>
              </div>
            </article>
            <img
              className='basic-wire'
              src={basicWire}
              alt='fala'
            />
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Intencje;
